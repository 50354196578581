@tailwind base;
@tailwind components;
@tailwind utilities;

/* Swiper */
.swiper {
  @apply w-full h-full;
}

.swiper-slide {
  @apply text-center text-lg flex justify-center items-center bg-gray-500 w-full h-screen;
}

/* NavigationBar */
.nav-button {
  @apply text-white hidden sm:block rounded hover:bg-logo-blue sm:py-1 sm:px-2 sm:text-sm md:text-lg md:py-2 md:px-4;
}

.nav-menu-button {
  @apply text-white text-3xl my-4;
}

/* Homepage */
/* App */
.homepage-icon {
  @apply text-white h-40 items-center;
}

/* Intro */
.intro {
  @apply w-full text-center md:text-left;
}

.intro-title {
  @apply inline md:block;
}

.intro-point {
  @apply font-bold text-red-500;
}

.intro-description {
  @apply leading-relaxed !important;
}

/* Page */
.page {
  @apply flex flex-col md:flex-row items-center justify-center w-full md:h-[1000px];
}

/* Contact */
.contact-logo {
  @apply flex items-center justify-center w-1/3;
}

/* Animation */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.left-slide-in {
  animation: slideInFromLeft 1s ease-out;
}

.bottom-slide-in {
  animation: slideInFromBottom 1s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-out;
}
